import { Box, Button, Link, Typography } from '@mui/material';
import { ClearAllOutlined as ClearAllOutlinedIcon } from '@mui/icons-material';

import Grid from '@mui/material/Unstable_Grid2';
import { FC, useMemo, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useComponentContext } from './ProposalsListViewContext';
import { SortOrder } from 'components/ui/Table/components/HeaderCell/HeaderCell';
import { proposalsTableHead } from 'constants/proposalsTable';
import { ConfirmationButton } from 'components/ConfirmationButton/ConfirmationButton';
import { useHistory } from 'react-router';
import paths from 'constants/paths';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';
import { ExportToExcel } from '../ExportToExcel/ExportToExcel';
import { StickyHeaderTable } from 'components/ui/Table/StickyHeaderTable';
import { useProposalsNamesAutocompleteDataHook } from 'hooks/proposalNamesAutocompleteDataHook';
import { ProposalEntityType } from 'graphql/proposals/types/graphql-types';
import { usePartiesNamesAutocompleteDataHook } from 'hooks/partiesNamesAutocompleteDataHook';
import { useUsersAutocompleteDataHook } from 'hooks/usersAutoCompleteDataHook';
import { PopoverForm } from 'components/PopoverForm/PopoverForm';
import { FilterForm } from 'components/ui/FilterForm/FilterForm';
import { ArrowIcon } from 'components/ArrowIcon/ArrowIcon';
import { filtersExtensionTableHead } from 'constants/filtersExtensionTable';

import { ExportToChart } from '../ExportToChart/ExportToChart';

export interface ITableViewProps {
  onReady?: () => void;
  printView?: boolean;
}

export const ProposalsListView: FC<ITableViewProps> = ({ onReady, printView }) => {
  const history = useHistory();

  const {
    totalItems,
    proposals,
    loadPage,
    pageLoadParams,
    loading,
    onFilterChange,
    filterApplied,
    clearAllFilters,
    onProposalSelect,
    filterOptions,
    filterValues,
  } = useComponentContext();

  const [filtersAnchorEl, setFiltersAnchorEl] = useState<null | HTMLElement>(null);
  const openFiltersPopover = Boolean(filtersAnchorEl);
  const openFilters = (event: Record<string, any>): void => {
    setFiltersAnchorEl(event.currentTarget);
  };

  const closeFilters = () => {
    setFiltersAnchorEl(null);
  };

  const gotoNewProposal = () => {
    history.push(paths.client.NEW_PROPOSAL);
  };

  const proposalsNamesProps = useProposalsNamesAutocompleteDataHook({
    search: filterValues['name'],
    entityType: ProposalEntityType.PROPOSAL,
  });

  const partiesNamesProps = usePartiesNamesAutocompleteDataHook({
    search: filterValues['party'],
  });

  const leadUserNamesProps = useUsersAutocompleteDataHook({
    search: filterValues['leadUser'],
  });

  const pendingReviewersNamesProps = useUsersAutocompleteDataHook({
    search: filterValues['pendingReviewers'],
  });

  const extendedProposalsTableHead = useMemo(() => {
    proposalsTableHead.find((item) => item.id === 'name')!.filterProps = proposalsNamesProps;
    proposalsTableHead.find((item) => item.id === 'party')!.filterProps = partiesNamesProps;
    proposalsTableHead.find((item) => item.id === 'leadUser')!.filterProps = leadUserNamesProps;
    proposalsTableHead.find((item) => item.id === 'pendingReviewers')!.filterProps =
      pendingReviewersNamesProps;

    return proposalsTableHead;
  }, [proposalsNamesProps, leadUserNamesProps, partiesNamesProps, pendingReviewersNamesProps]);

  const extendedFiltersExtensionTableHead = useMemo(() => {
    if (filterValues?.newParty?.id !== 'Custom') {
      return filtersExtensionTableHead.filter((item) => item.id !== 'partyCreatedAtPeriod');
    }

    return filtersExtensionTableHead;
  }, [filterValues]);

  return (
    <LoadingOverlay spinner active={loading && !printView} text="Loading your content...">
      <PopoverForm open={openFiltersPopover} anchorEl={filtersAnchorEl} handleClose={closeFilters}>
        <FilterForm
          headCells={extendedFiltersExtensionTableHead}
          loadPage={loadPage}
          filterOptions={filterOptions}
          filterValues={filterValues}
          onFilterChange={onFilterChange}
          initRowsPerPage={pageLoadParams.rowsPerPage}
        ></FilterForm>
      </PopoverForm>

      <Grid container padding={0} spacing={2}>
        <Grid xs={12}>
          <Grid container padding={0} spacing={2}>
            <Grid xs={4} style={{ padding: '16px', marginTop: '16px' }}>
              <ConfirmationButton
                action={gotoNewProposal}
                text="Create New Proposal"
              ></ConfirmationButton>
            </Grid>

            <Grid xs={4}>
              {filterApplied ? (
                <Box display="flex" justifyContent="center" mt={2}>
                  <Button
                    onClick={debounce(clearAllFilters, DEBOUNCE_TIMEOUT)}
                    variant="outlined"
                    size="small"
                    endIcon={<ClearAllOutlinedIcon />}
                  >
                    Clear Filters
                  </Button>
                </Box>
              ) : undefined}
            </Grid>

            <Grid
              xs={4}
              sx={{ display: 'flex', alignContent: 'center', justifyContent: 'flex-end' }}
            >
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems={'center'}
                paddingRight={'10px'}
              >
                <ExportToChart pageLoadParams={pageLoadParams} totalItems={totalItems} />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12}>
          <Link component="button" onClick={openFilters} style={{ paddingLeft: '10px' }}>
            <Typography variant="caption">Add filters</Typography>
            <ArrowIcon down={true} />
          </Link>
        </Grid>
        <Grid xs={12}>
          <Box
            style={{
              borderRadius: '1px',
              boxShadow: '0px 2px 5px #00000030',
              border: '1px solid #00000030',
            }}
          >
            <StickyHeaderTable
              totalItems={totalItems}
              dataCells={proposals}
              headCells={extendedProposalsTableHead}
              loadPage={loadPage}
              handleSelect={onProposalSelect}
              filterOptions={filterOptions}
              filterValues={filterValues}
              onFilterChange={onFilterChange}
              initRowsPerPage={pageLoadParams.rowsPerPage}
              printView={printView}
              initOrder={pageLoadParams.order || SortOrder.ASC}
              initOrderBy={pageLoadParams.orderBy}
              initPage={pageLoadParams.page}
              paginationSideComponent={
                <ExportToExcel
                  pageLoadParams={pageLoadParams}
                  totalItems={totalItems}
                ></ExportToExcel>
              }
            ></StickyHeaderTable>
          </Box>
        </Grid>
      </Grid>
    </LoadingOverlay>
  );
};
